import React from "react"
import Layout from "../components/Layout"

const SentMessageSuccessPage = () => (
  <Layout hideHamburger={true}>
    <p>
      Grazie!
      <br />
      Il tuo messaggio è stato inviato correttamente!
    </p>
  </Layout>
)

export default SentMessageSuccessPage
